@import "../../scss/variables";

.spinner > div {
  margin: auto;

  div:nth-child(7n + 1) {
    background-color: $logo-color-yellow;
  }
  div:nth-child(7n + 2) {
    background-color: $logo-color-orange;
  }
  div:nth-child(7n + 3) {
    background-color: $logo-color-pink;
  }
  div:nth-child(7n + 4) {
    background-color: $logo-color-fuschia;
  }
  div:nth-child(7n + 5) {
    background-color: $logo-color-purple;
  }
  div:nth-child(7n + 6) {
    background-color: $logo-color-blue;
  }
  div:nth-child(7n + 7) {
    background-color: $logo-color-mint;
  }
}

.centeredSpinner {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}
