@import "../../../scss/FormStyles.module.scss";

.summaryError {
    color: red;
    font-weight: 600;
}

// I'd like to create reusable styles for this radio selection list thing
// But for now...

.radioSection {
    background-color: #F5F4F4;
    margin-bottom: 10px;
    padding:20px;
}

.radioSection > input[type=radio] {
    position: absolute;
    visibility: hidden;
}

.radioSection .check {
    border: 1px solid #B4BFC9;
    border-radius: 100%;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.08);
    background-color:#FFF;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

:global(.selected).radioSection .check {
    border-color: #007AFF;
    background: #007AFF; // Default to solid if they don't support gradients.
    background: radial-gradient(ellipse at center, #007aff 0%,#007aff 40%, #ffffff 35%,#ffffff 100%);
}

.radioSectionHeader {
    display: flex;
    align-items: center;
}

:global(.selectable) .radioSectionHeader {
    cursor: pointer;
}

.radioSectionHeader > * {
    flex: 1;
}

.radioSectionHeader > .check {
    flex: none;
}

.radioSectionHeaderRight {
    text-align:right;
}

.radioSectionBody {
    overflow: hidden;
    margin-top:0;
}

.padTopBody {
    margin-top:20px;
}

// :global(.selected) .radioSectionBody {
//     animation: slide-out 1s ease-in-out;
// }

:global(.selectable):global(.selected) .radioSectionBody {
    animation: slide-out 1s ease-in-out;
}

@keyframes slide-out {
    0% {
        max-height: 0;
    }
    10% {
        max-height: 0;
    }
    100% {
        max-height: 2000px; // Setting to something gigantic. You can't transition 'auto'
    }
}

// .radioSection.selected 