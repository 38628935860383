@import "../../../scss/FormStyles.module.scss";

.summaryError {
  color: red;
  font-weight: 600;
}

.fallbackAlert {
  color: $error-red;
  font-weight: bold;
  background-color: mix($error-red, white, 10%);
  padding: 20px;
}