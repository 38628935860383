@import "../../scss/variables";
@import "../../scss/mixins";

.contactInfoHeader {
  color: $logo-color-grey;
}

.socialLogo {
  height: 3rem;
  width: 3rem;
  margin: 0.5rem;
}