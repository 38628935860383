@import "variables";
@import "functions";
@import "mixins";
@import "fonts";
// @import "screen-size";

body {
  font-family: $font-main;
  font-size: $font-size-default;
  color: $font-color-default;
}

.no-scroll,
.no-scroll-full-screen {
  overflow: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (min-width: $screen-xl-min) {
  .container {
    max-width: 1330px;
    width: auto;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: $screen-lg-min) {
  // TODO: Breakdown list item into component without columns
  .suggestedProductList {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
