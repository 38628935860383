@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";

.emoji_wrapper {
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 100;
}
