// Fonts
$font-main: "museosans", sans;
$font-secondary: "steelfish", sans;
$font-size-default: 16px;
$font-color-default: #4a4a4a;


// Colors
$logo-color-yellow: #fed141;
$logo-color-orange: #ff9d6e;
$logo-color-pink: #f67599;
$logo-color-fuschia: #dd7fd3;
$logo-color-purple: #9595d2;
$logo-color-blue: #8bb8e8;
$logo-color-mint: #64ccc9;
$logo-color-grey: #888b8d;

$disabled-grey: #D7D5D5;
$error-red: #ce1126;

$colors: () !default;
$logo-colors: map-merge(
  (
    "yellow": $logo-color-yellow,
    "orange": $logo-color-orange,
    "pink": $logo-color-pink,
    "fuschia": $logo-color-fuschia,
    "purple": $logo-color-purple,
    "blue": $logo-color-blue,
    "mint": $logo-color-mint
  ),
  $colors
);

$logo-colors-list: (
  $logo-color-yellow,
  $logo-color-orange,
  $logo-color-pink,
  $logo-color-fuschia,
  $logo-color-purple,
  $logo-color-blue,
  $logo-color-mint
);

$error-backdrop-pink: #f7d0c7;

// Buttons
$btn-primary: $logo-color-fuschia;
$btn-secondary: $logo-color-purple;
$btn-remove: $logo-color-grey;
$btn-success: $logo-color-mint;
$btn-danger: $logo-color-orange;
$btn-warning: $logo-color-yellow;
$btn-disabled: $disabled-grey;

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
$screen-sm-min: 576px;
// Medium devices (tablets, 768px and up)
$screen-md-min: 768px;
// Large devices (desktops, 992px and up)
$screen-lg-min: 992px;
// Extra large devices (large desktops, 1200px and up)
$screen-xl-min: 1200px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Small devices (landscape phones, 576px and up)
@media (min-width: $screen-sm-min) {
}
// Medium devices (tablets, 768px and up)
@media (min-width: $screen-md-min) {
}
// Large devices (desktops, 992px and up)
@media (min-width: $screen-lg-min) {
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: $screen-xl-min) {
}
