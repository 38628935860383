@import "../scss/variables";
@import "../scss/mixins";
@import "../scss/functions";
@import "../scss/fonts";

.input_box {
  @include bless-inputs;

  &.invalid {
    @include bless-inputs-invalid;
  }
}

.input_header {
  @include bless-input-labels;
}

.input_state {
  @extend .input_box;
  width: 4rem;
  margin-right: 1.63rem;
}

.receiptTxt {
  font-weight: 500;
  font-size: 0.625rem;
  color: #858585;
  line-height: 22px;
  float: right;
}

#checkout-frame iframe {
  width: 100%;
}

.estimatedTotal {
  float: right;
}

.input_header_shipping {
  font-size: 0.8125rem;
  font-weight: 700;
  color: #4a4a4a;
  margin-bottom: 20px;
}

/* Customize the label (the sameAsShipping) */
.sameAsShipping, .sameAsBilling {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 0.625rem;
  color: #858585;
  line-height: 22px;
  border-color: blue;
  float: right;
}

/* Hide the browser's default checkbox */
.sameAsShipping input, .sameAsBilling input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: 1px solid #4792e6;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.sameAsShipping:hover input ~ .checkmark, .sameAsBilling:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.sameAsShipping input:checked ~ .checkmark, .sameAsBilling input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.sameAsShipping input:checked ~ .checkmark:after, .sameAsBilling input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.sameAsShipping .checkmark:after, .sameAsBilling .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #4792e6;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.inputfailure {
  color: $error-red;
  font-size: 85%;
  min-height:1.5em;
}