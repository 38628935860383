@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";

.button {
  @include bless-button($btn-primary);
}

.disabled {
  @include bless-button($btn-disabled);
}
