@import url('https://fonts.googleapis.com/css?family=Covered+By+Your+Grace');

@font-face {
  font-family: 'museosans';
  font-weight: 100;
  font-style: italic;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-100-italic.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-100-italic.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-100-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 100;
  font-style: normal;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-100.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-100.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-100.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 300;
  font-style: italic;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-300-italic.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-300-italic.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-300-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 300;
  font-style: normal;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-300.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-300.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-300.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 500;
  font-style: italic;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-500-italic.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-500-italic.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-500-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 500;
  font-style: normal;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-500.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-500.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-500.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 700;
  font-style: italic;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-700-italic.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-700-italic.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-700-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 700;
  font-style: normal;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-700.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-700.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-700.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 900;
  font-style: italic;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-900-italic.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-900-italic.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-900-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'museosans';
  font-weight: 900;
  font-style: normal;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-900.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-900.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/museosans-900.ttf') format('truetype');
}

@font-face {
  font-family: 'steelfish';
  font-style: normal;
  src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish.woff2') format('woff2'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish.ttf') format('truetype');
}



// @font-face {
//   font-family: 'steelfish';
//   font-style: bold;
//   src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish_bold.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish_bold.woff2') format('woff2'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish_bold.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish_bold.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'steelfish';
//   font-style: italic;
//   src: url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish_italic.eot?') format('eot'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish_italic.woff2') format('woff2'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish_italic.woff') format('woff'), url('https://d3o7jpqgb2hslk.cloudfront.net/fonts/steelfish_italic.ttf') format('truetype');
// }
