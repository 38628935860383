@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";

.size_select {
  color: #000000;
  border-color: #000000;
  border-radius: 0px;
  background-color: #ffffff;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 5rem;
  padding: 0.6em;
}
.size_select:hover {
  background-color: #f67599;
  color: #ffffff;
  border-color: #f67599;
}

.selected {
  background-color: #f67599;
  color: #ffffff;
  border-color: #f67599;
}