@import "../../scss/variables";
@import "../../scss/mixins";

.logo {
  height: 0.875rem;
  width: auto;
  vertical-align: text-bottom;
  margin-left: 2px;
  margin-right: 5px;
}

.terms {
  font-size: 0.75rem;
}

.notes + .notes {
  margin-left: 10px;
  border-left: 1px solid $font-color-default;
  padding-left: 10px;
}

.cardStyling {
  position: relative;
  width: 80%;
  left: 40%;
  bottom: 0;
  margin-left: calc(-80% / 2);
}

@media (max-width: $screen-lg-max) {
  .cardStyling {
    position: absolute;
    width: 80%;
    left: 50%;
    bottom: auto;
    margin-left: calc(-80% / 2);
  }
}
