@import "../../scss/variables";
@import "../../scss/mixins";

.aboutHeader {
  font-family: "steelfish", sans;
  text-transform: uppercase;
  color: $logo-color-pink;
  font-size: 2.25rem;
  letter-spacing: 0.04rem;
  line-height: 2.25rem;
  margin: 20px 0 10px;
}

.imageFrame {
  border-radius: 50%;
  padding-bottom: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.socialLogo {
  margin-left: 20px;
  max-width: 40px;
}

.iconRow:first-child a:first-child img {
  margin-left: 0px;
}

.image {
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  transform: translate(-50%, -50%);
}

.profilePicMargin {
  margin-left: 15%;
}

.bioFrame {
  color: #4a4a4a;

  h2 {
    font-weight: normal;
  }

  p {
    line-height: 1.63rem;
  }
}

.button {
  @include bless-button($btn-primary);
}
.buttonSolo {
  @include bless-button($btn-primary);
  display: block;
  margin: auto;
  width: 28.4em;
}

.video {
  @include responsive-video;
}

.floatingBio {
  width: 500px;
  min-height: 621px;
  margin: auto;
  margin-top: 2.5rem;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.12),
    0 12px 15px 0 rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  margin-bottom: 10px;
}

.imageSolo {
  top: 50%;
  left: 50%;
  height: 92%;
  width: 92%;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  transform: translate(-50%, -50%);
}

.ownerNameSolo {
  font-family: "museosans";
  font-weight: 500;
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: -0.39px;
  line-height: 22px;
  text-align: center;
}

.bioDetail {
  font-family: "museosans";
  font-weight: 100;
  font-size: 12px;
  color: #434343;
  letter-spacing: 0.08px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 30px;
  
}

.registerDetail {
  font-family: "museosans";
  font-weight: 300;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0;
  text-align: center;
  line-height: 26px;
  text-align: left;
  margin: auto;
  margin-bottom: 30px;
}

.noProfilePic {
  display: block;
  margin: auto;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-image: linear-gradient(#8bb8e8, #e56b8a);
  position: relative;
  margin-bottom: 6px;
}

.initials {
  position: absolute;
  color: #ffffff;
  left: 30%;
  top: 30%;
  font-family: "museosans";
  font-weight: 100;
  font-size: 48px;
  letter-spacing: -1.16px;
}

.logoStyling {
  margin: auto;
  display: block;
}

.profileBorder {
  position: relative;
  display: block;
  margin: auto;
  height: 165px;
  width: 165px;
  border-radius: 50%;
  background-image: linear-gradient(#8bb8e8, #e56b8a);
  margin-bottom: 6px;
}

.centerName {
  text-align: center;
}

.centerBio {
  margin: auto;
  margin-bottom: 30px;
  max-width: 55%;
  text-align: center;
}

@media (max-width: $screen-lg-max) {
  .bioFrame {
    margin: auto;
    color: #4a4a4a;
  
    h2 {
      font-weight: normal;
    }
  
    p {
      line-height: 1.63rem;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .floatingBio {
  max-width: 100%;
  margin-top: 2.5rem;
  background: #ffffff;
  border: none;
  box-shadow: none;
  border-radius: 2px;
  }
}