@import "../../scss/variables";
@import "../../scss/mixins";

@mixin jump-to-recent() {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
  @include bless-button($btn-primary);
}

.full_screen_mode {
  @include bless-button($btn-primary);

  &_close {
    color: #fff;
    padding: 3px 6px;
    line-height: 1;
    float: right;
  }

  &_inventory,
  &_inventory_hide_button {
    @include bless-button($btn-primary);
    padding: 0;
    line-height: 1;
  }

  &_inventory {
    position: fixed;
    z-index: 200;
    top: 65px;
    right: 10px;
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }

  &_inventory_hide {
    text-align: center;

    &_button {
      margin-top: -26px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }
  }
}

.live_inventory {
  min-height: 400px;

  &_empty {
    min-height: 400px;
    height: 100%;
    width: 100%;

    &_border {
      height: 100%;
      width: 100%;
      border: 1px solid #e8e7e7;
    }

    &_content {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &_emoji {
      font-size: 3rem;
      color: $disabled-grey;
    }

    &_text {
      font-size: 0.75rem;
      color: $logo-color-grey;
    }
  }

  &_full_screen {
    position: fixed;
    z-index: 200;
    min-height: 180px;
    width: 100%;
    bottom: 58px;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  &_scroll {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    &_full_screen {
      width: 100%;
    }

    &_down {
      white-space: nowrap;
      overflow: scroll;
      text-align: center;
      display: flex;
    }
  }

  &_items_full_screen {
    width: 130px;
    min-width: 130px;
    display: inline-block;
    padding: 5px 15px 0;
    text-align: initial;
    color: #fff;
  }

  &_jump_to_recent {
    @include jump-to-recent();

    &_wrapper {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
