@import "../../scss/variables";
@import "../../scss/mixins";

.bless_h3 {
  @include bless-h3;
}

.live_sale {
  position: relative;

  &_full_screen {
    .video {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      background: #000;
    }

    .badge {
      background: none;

      &_live {
        background: #fd3d3e;
      }

      &_wrapper {
        position: fixed;
        z-index: 200;
        top: 0;
        left: 0;
        padding: 15px;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.video {
  @include responsive-video(3, 4);
  background: #000;
}

@mixin jump-to-recent() {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
  @include bless-button($btn-primary);
}

.full_screen_mode {
  @include bless-button($btn-primary);

  &_close {
    color: #fff;
    padding: 3px 6px;
    line-height: 1;
    float: right;
  }

  &_inventory,
  &_inventory_hide_button {
    @include bless-button($btn-primary);
    padding: 0;
    line-height: 1;
  }

  &_inventory {
    position: fixed;
    z-index: 200;
    top: 65px;
    right: 10px;
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }

  &_inventory_hide {
    text-align: center;

    &_button {
      margin-top: -26px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }
  }
}

.live_chat {
  background: #f9f8f8;
  height: 100%;
  position: relative;
  min-height: 400px;

  &_full_screen {
    background: none;
    position: fixed;
    height: 365px;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 120;
  }

  &_timestamp {
    font-size: 10px;
    color: #959595;
    letter-spacing: 0.17px;
    text-align: right;

    &_fullscreen {
      font-size: 10px;
      letter-spacing: 0.17px;
      text-align: right;
      padding-top: 5px;
      color: #ffffff;
    }
  }

  &_info {
    background: #ffffff;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: table;
    width: 100%;
  }

  &_cover {
    width: 50px;
    display: table-cell;
    vertical-align: middle;

    &_img img {
      border-radius: 50%;
      border: 1px solid $logo-color-pink;
    }
  }

  &_text {
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;
  }

  &_header {
    font-size: 0.875rem;
    color: #4a4a4a;
  }

  &_times {
    font-size: 11px;
    color: #979797;
  }

  &_area {
    padding: 10px;
    position: absolute;
    overflow-y: auto;
    top: 70px;
    bottom: 58px;
    width: 100%;
  }

  &_comment_total {
    font-size: 0.625rem;
    color: #858585;
    padding: 10px 0 0 10px;
  }

  &_jump_to_recent {
    @include jump-to-recent();
  }
}

.muted .live_chat_full_screen {
  pointer-events: none;
  
  .live_chat_area {
    display:none;
  }
}

.badge {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 0.875rem;
  line-height: 25px;

  &_live {
    background: #fd3d3e;
  }

  & + & {
    margin-left: 5px;
  }

  &_wrapper {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
  }
}

div.fallback {
  p.fallbackAlert {
    color: $error-red;
    font-weight: bold;
    background-color: mix($error-red, white, 10%);
    padding: 20px;
  }
  ol {
    margin: 0;
    padding-left: 15px;
  }
  img {
    width: 90%;
    width: calc(100% - 20px);
    margin: 15px 10px;
    box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.1);
  }
}
