.SignupContainer {
    width: 100%;
    color: #FFF;
    text-align: center;
    position: relative;
}

.SignupText {
    font-family: 'museosans', sans-serif;
    font-weight: 900;
    font-style: italic;
    font-size: 18px;
    line-height: 35px;
    display: inline-block;
    width: 295px;
}
.ExpanderIcon {
    height: 9px;
    margin: 26px 5px;
    transition: transform 0.15s ease;
}

.SignupText, .SignupInput, .SignupSubmit {
    height: 35px;
    margin: 13px 10px;
}

.SignupForm, .SignupFormWrapper {
    display: inline-block;
}

.SignupInput {
    border: 1px solid #EAEAEA;
    border-radius: 2px;
    width: 150px;
    padding: 0px 15px;
}

.SignupInput[type=email] {
    width: 225px;
}

button.SignupSubmit {
    background-color: #D45FC8;
    border: #FFF solid 1px;
    border-radius: 2px;
    color: #FFF;
    font-family: 'museosans', sans-serif;
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 0.05em;
    padding: 0 35px;
    cursor: pointer;

    &:hover {
        background-color: #C24DB6;
    }
}

.SignupDisclaimer {
    background-color: #616465;
    font-family: "roboto", sans-serif;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #FFFFFF;
    padding: 11px;
    margin: auto;
    text-align: center;

    a {
        color: #FFF;
        text-decoration: underline;
    }
}

.SignupExpandoSection {
    background-color: #D45FC8;
}

.CompleteText {
    background-color: #D45FC8;
    font-family: 'museosans', sans-serif;
    font-weight: 900;
    font-style: italic;
    font-size: 18px;
    line-height: 18px;
    padding: 12px;
    vertical-align: middle
}

.CompleteTextIcon {
    font-size: 32px;
    line-height: 38px;
    vertical-align: sub;
}

:global(.full-screen-live) .SignupContainer {
    display:none;
}

@media screen and (min-width: 1050px) {
    .SignupExpander {
        display: none;
    }
}

@media screen and (max-width: 1049px) {
    .SignupExpander {
        background-color: #D45FC8;
    }
    
    .DesktopOnly {
        display: none;
    }
    .SignupText {
        font-size: 14px;
        width: auto;
        margin-left: 32px;
    }
    .SignupExpander {
        position: relative;
        z-index: 400;
    }
    .SignupExpandoSection {
        transition: height 0.5s ease;
        overflow: hidden;
        pointer-events: none;
        z-index: 399;
        height: 0;
        background-color: transparent;
    }
    .SignupContainer:global(.expanded) {
        .SignupExpandoSection {
            pointer-events: all;
            height: 250px;
        }
        .ExpanderIcon {
            transform: rotate(180deg);
        }
    }

    .SignupForm {
        padding-bottom: 10px;
        max-width: 500px;
        background-color: #D45FC8
    }

    .SignupFormWrapper {
        width: 100%;
        background-color: #D45FC8;
        display: block;
    }

    .SignupInput, button.SignupSubmit {
        margin: 10px;
    }

    .SignupInput {
        width: 40%;
        width: calc(50% - 20px);
    }
    
    .SignupInput[type=email], button.SignupSubmit {
        width: 90%;
        width: calc(100% - 20px);
    }

    button.SignupSubmit {
        height: 45px;
    }

    .CompleteText {
        padding-bottom:20px;
        // padding-left: 20%;
        // padding-left: calc(20%+44px);
        // padding-right: 20%;
    }
}