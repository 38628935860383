@import "../../scss/variables";
@import "../../scss/mixins";

.message {
  padding-top: 16px;
}

.text {
  position: relative;
  background: $logo-color-blue;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.2px;
  float: right;
  margin-bottom: 10px;
  min-height: 38px;

  &::before {
    content: "";
    position: absolute;
    bottom: -6px;
    right: 10px;
    border-top: 4px solid $logo-color-blue;
    border-right: 8px solid $logo-color-blue;
    border-bottom: 4px solid transparent;
    border-left: 8px solid transparent;
  }

  &_full_screen {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid $logo-color-fuschia;

    &::before {
      display: none;
    }
  }
}
