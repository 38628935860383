@import "../scss/variables";
@import "../scss/mixins";
@import "../scss/functions";

.textStyling {
  padding-top: 15%;
  text-align: center;
}

.textStyling h1 {
  font-family: "museosans";
  font-weight: 300;
  font-style: normal;
}

.textStyling h1 a {
  text-decoration: none;
}

.errorImageStyle {
  padding-bottom: 2rem;
}

.logoStyling {
  left: 0;
  right: 0;
  position: fixed;
  margin: auto;
  bottom: 0;
}
