.table {
  width: 100%;
  font-size: 0.8125rem;
  margin-bottom: 20px;

  td {
    padding-bottom: 10px;

    &:last-child {
      text-align: right;
    }
  }

  tr:last-child td {
    padding: 10px 0 0;
    font-weight: 700;
  }
}

.uppercase {
  text-transform: uppercase;
}
