@import "../../scss/variables";
@import "../../scss/mixins";

.text_input {
  @include bless-inputs;
}

.col_left {
  padding: 40px 20px;
  background-color: #f5f4f4;
}

.col_message {
  font-size: 0.7rem;
  line-height: 2rem;
}

.input_header {
  @include bless-input-labels;
}

.button {
  @include bless-button($btn-primary);
}

.guestButton {
  background-color: gray;
  color: #FFF;
  width: 248px;
  height: 40px;
  margin-top: 15px;
}

.btnFacebook { 
  width: 248px;
  height: 40px;  
  border-radius: 4px;
  background-color: #1877F2;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin:5px;
  display: inline-block;
  margin-left: 0px;
}

.btnFacebook:hover {
  cursor: pointer;
}

.continueAsButton {
  min-width: 248px;
  height: 40px;  
  border-radius: 4px;
  background-color: #1877F2;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin:5px;
  display: inline-block;
  margin-left: 0px;
  padding-left: 15px;
  padding-right: 0px;
  padding-top: 0px;
  cursor: pointer;
}

.facebookButton {
  min-width: 248px;
  height: 40px;  
  border-radius: 4px;
  background-color: #1877F2;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin:5px;
  display: inline-block;
  margin-left: 0px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.profilePicture {
  height: 40px;
  margin-left: 10px;
  border-radius: 0px 5px 5px 0px;
}