@import "../../scss/variables";
@import "../../scss/mixins";

.video_stream_container {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 100%;
  width: 100%;

  & video {
    width: 100%;
    height: 100%;
  }
}

.not_streaming {
  position: absolute;
  width: 70%;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;

  &_heading {
    text-transform: uppercase;
  }
}

.mute_button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  text-align: center;
  color: #fff;
  fill: #fff;
}

.heart_overlay {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  width: 50px;

  &_full_screen {
    position: fixed;
    top: 65px;
    cursor: pointer;
    width: 50px;
    left: 10px;
  }
}

.canvas {
  position: absolute;
  height: 100%;
}
