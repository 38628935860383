.agreementBanner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    width: 100%;
    height: auto;
    padding: 20px 10px;
    z-index: 9999;

    @media (max-width: 456px) {
        padding: 10px 5px;

        .agreementBannerContent {
            font-size: 14px !important;
            padding: 0 5px;
        }

        .agreementBannerButton {
            padding: 0 5px;
        }
    }

    &[data-location="top"] {
        top: 0;
    }

    &[data-location="bottom"] {
        bottom: 0;
    }

    .agreementBannerContent {
        padding: 0 10px;
        font-size: 18px;
        color: white;
    }

    .agreementBannerButton {
        padding: 0 10px;
    }
}
