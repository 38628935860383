.itemImageArea {
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;

  .itemImage {
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}
