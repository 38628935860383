@import "../../scss/variables";
@import "../../scss/mixins";

.submit {
  @include bless-button($btn-primary);
  width: 100%;
}

.stars {
  font-size: 2.5rem !important;
}

.commentsContainer {
  width: 100%;
  margin-bottom: 0;
}

.comments {
  resize: none !important;
}

.ratingLabel {
  min-height: 1.5rem;
  margin-left: 0.5rem !important;
  font-family: "museosans", sans;
  font-weight: 300;
  color: $logo-color-grey;
}

.remainingCharacters {
  margin-bottom: 1rem;
}

.overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 100;
}

.formContainer {
  position: relative;
  z-index: -100;
}

.success {
  color: $logo-color-pink;
  margin-top: 5px;
}

.error {
  color: $error-red;
  margin-top: 5px;
}

@media (min-width: $screen-lg-min) {
  .commentsContainer {
    width: 50%;
  }
  .submit {
    @include bless-button($btn-primary);
    width: auto;
  }
}
