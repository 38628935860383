@import "variables";

// Typography
@mixin headings {
  font-family: $font-secondary;
  color: $logo-color-pink;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
}

@mixin bless-h1 {
  @include headings;
  font-size: 3rem;
}

@mixin bless-h2 {
  @include headings;
  font-size: 2.5rem;
}

@mixin bless-h3 {
  @include headings;
  font-size: 2rem;
}

@mixin bless-h4 {
  @include headings;
  font-size: 1.75rem;
}

@mixin bless-h5 {
  @include headings;
  font-size: 1.5rem;
}

@mixin bless-h6 {
  @include headings;
  font-size: 1.25rem;
}

// Inputs
@mixin bless-input-labels {
  font-weight: 700;
  font-size: 0.8125rem;
}

@mixin bless-inputs {
  border: 1px solid $logo-color-grey;
  color: $font-color-default;
  border-radius: 0;
}

@mixin bless-inputs-invalid {
  border-color: $error-red;
  border-width: 2px;
}

// Buttons
@mixin bless-button($btn-background, $btn-color: #fff) {
  background: $btn-background;
  border-color: $btn-background;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.04rem;
  padding: 1rem 2rem;
  border-radius: 0;
  color: $btn-color;

  &:hover {
    color: $btn-color;
    background: darken($btn-background, 8%);
    border-color: darken($btn-background, 8%);
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    background: darken($btn-background, 25%);
    background-color: darken($btn-background, 25%);
    border-color: darken($btn-background, 25%);
  }

  &.focus,
  &:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem lighten($btn-background, 8%);
  }

  &.disabled,
  &:disabled {
    color: $btn-color;
    background: $disabled-grey;
    border-color: $disabled-grey;
    opacity: 1;
    cursor: not-allowed;
  }
}

// Video
@mixin responsive-video($width: 16, $height: 9) {
  overflow: hidden;
  padding-bottom: $height / $width * 100%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
