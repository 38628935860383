@import "../../../scss/variables";
@import "../../../scss/mixins";
@import "../../../scss/functions";

.container {
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: $error-backdrop-pink;
    border-radius: 8px;

    & p {
        font-family: museosans;
        color: mix(white, $font-color-default, 20%);
        font-size: 1.0rem;

        &.header {
            font-weight: 700;
            text-transform: uppercase;
            color: $font-color-default;
            font-size: 1.0rem;
            margin-bottom: 6px;
        }
    }
}
