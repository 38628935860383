@import "./scss/variables";
@import "./scss/mixins";

.text {
  display: "inline-block";
  padding-left: 2.5vw;
}

.image {
  width: 100%;
  margin-top: 6rem;
}

.button {
  @include bless-button($btn-primary);
}

.headerText {
  font-family: "steelfish", sans;
  text-transform: uppercase;
  color: $logo-color-pink;
  font-size: 2.5rem;
  letter-spacing: 0.04rem;
  line-height: 2.69rem;
}

.detailText {
  font-family: "museosans";
  font-size: 13px;
  color: $font-color-default;
  letter-spacing: 0;
  line-height: 23px;
}

.primaryText {
  margin-left: 0px;
  display: block;
  font-family: "museosans";
  font-weight: 100;
  font-size: 41px;
  letter-spacing: 0;
  color: $font-color-default;
}

.secondaryText {
  display: block;
  margin-left: 0px;
  margin-bottom: 1.5vw;
  font-family: "museosans";
  font-weight: 900;
  font-size: 38px;
  color: $font-color-default;
}

@media (min-width: $screen-md-min) {
  .image {
    width: 100%;
    margin-top: 0px;
  }
}