// .container {
//   margin: 2.63rem auto;
// }

// @media screen and (min-width: 1200px) {
//   .container {
//     width: auto;
//     max-width: 1330px;
//   }
// }

.toast {
  color: white;
  background-color: #dd7fd3;
}
