@import "../../scss/mixins";
@import "../../scss/variables";

.listItem {
  margin-bottom: 50px;
}

.loadingItemImage,
.loadingItemText {
  width: 100%;
}

.loading-item-wrapper {
  height: 0;
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.loadingItemImage {
  border: 1px solid rgb(192, 192, 192);
}

.loadingItemText {
  padding-top: 10px;
  height: 35px;
  overflow: hidden;
}

.add_to_bag {
  @include bless-button($btn-primary);
  width: 100%;
  padding: 0.6em;
}

.remove_from_cart {
  @include bless-button($btn-remove);
  width: 100%;
  padding: 0.6em;
}
