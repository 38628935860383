@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";

.bless_h3 {
  @include bless-h3;
}

.checkout_header {
  font-weight: 500;
  font-size: 1.125rem;
  padding-bottom: 1rem;
}

.arrow {
  position: absolute;
  right: 0;
  top: 6px;

  &_wrapper {
    display: none;
    overflow: hidden;
    position: relative;
    height: 22px;
    float: right;
    width: calc(100% - 245px);
  }
}

.summary {
  background: #f5f4f4;
  padding: 20px;
  margin-top: 20px;
}

.order_number {
  font-weight: 300;
  font-size: 0.8125rem;
  line-height: 22px;
}

.checkout_item {
  & + & {
    margin-top: 8px;
  }

  &_image_area {
    width: 70px;
    height: 80px;
    overflow: hidden;
    position: relative;
    float: left;
    margin-right: 10px;
  }

  &_image {
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.albumListItem {
  display: inline-block;
  width: 14.06rem;
  height: 16.25rem;
  margin: 2rem;
  background-color: #d6d6d6;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $screen-sm-min) {
  .album_list_overwrite > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $screen-md-min) {
  .arrow_wrapper {
    display: block;
  }

  .album_list_overwrite > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $screen-xl-min) {
  .album_list_overwrite > div {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.shopping_cart_size {
  font-family: "museosans";
  font-weight: 300;
  font-size: 12px;
  color: #4A4A4A;
  letter-spacing: 0.67px;
  line-height: 16px;
}
