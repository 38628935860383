@import "../../scss/variables";
@import "../../scss/mixins";

.live_chat_input {
  display: table-cell;
  @include bless-inputs;
  padding-right: 36px;

  &_error {
    @include bless-inputs-invalid;

    &:focus {
      border-color: $error-red;
      box-shadow: 0 0 0 0.2rem rgba($error-red, 0.25);
    }

    &_text {
      color: $error-red;
    }
  }

  &_wrapper {
    position: absolute;
    bottom: 0;
    display: table;
    padding: 10px;
    background: #fff;
    width: 100%;
    box-shadow: 0 -8px 30px 0 rgba(0, 0, 0, 0.05);
  }

  &_grin {
    @include bless-button($btn-primary);
  }

  &_love {
    @include bless-button(#f25268);
  }

  &_like {
    @include bless-button(#5890ff);
  }

  &_send {
    @include bless-button($btn-primary);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1rem;
    display: inline-block;

    & svg {
      margin: -2px 0 0 -4px;
    }

    &_wrapper {
      position: absolute;
      top: -4px;
      right: -10px;
    }
  }

  &_grin,
  &_love,
  &_like {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    font-size: 1rem;
    line-height: 1rem;
    display: inline-block;
  }
}

.live_chat_jump_to_recent {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
  @include bless-button($btn-primary);
}

.emoji_buttons {
  display: table-cell;
  text-align: right;
  vertical-align: middle;
}
