@import "../../../scss/variables";

.container {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}

.image {
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.title {
  font-family: "museosans", sans;
  font-weight: 500;
  letter-spacing: 0.56px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  position: relative;
  bottom: 0;
  padding: 10px;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

.wrapperPlaceholder {
  cursor: normal;
  user-select: none;

  & .album_card {
    background-color: #f4f4f4;
  }

  & .title {
    background-color: #cecece;
  }
}

.no_link_underline {
    text-decoration: none !important;
}

.wrapper,
.wrapperPlaceholder {
  padding-bottom: 30px;
}

// When album items are listed next to each other,
// take the items and loop over the logo color list.
// Use the color itself for the button, and use a lighter
// version as a default for backgrounds.
@each $color in $logo-colors-list {
  $length: length($logo-colors-list);
  $index: index($logo-colors-list, $color);

  .wrapper:nth-child(#{$length}n + #{$index}) {
    & .album_card {
      // Mixing does better than lighten for this; otherwise
      // colors that are too close to white become light too quickly.
      background-color: mix($color, white, 20%);
    }

    & .title {
      background-color: $color;
    }

    &:hover .title {
      background-color: mix($color, black, 85%);
    }
  }
}
