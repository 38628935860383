@import "../../scss/variables";
@import "../../scss/mixins";

.add_to_bag {
  @include bless-button($btn-primary);
  padding: 0 10px;
  height: 52px;
  white-space: pre-wrap;
}

.its_yours {
  @include bless-button($logo-color-blue);
  padding: 0 10px;
  height: 52px;
  white-space: pre-wrap;
}

.disabled,
.sold {
  @include bless-button($btn-disabled, $font-color-default);
  padding: 0 10px;
  height: 52px;
  white-space: pre-wrap;
}

.live_inventory {
    &_img {
        border: 1px solid #d6d6d6;

        &_wrapper {
            position: relative;
        }
    }

    &_name {
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.67px;
        line-height: 16px;
        margin: 6px 0;
    }

    &_price {
        font-weight: 300;
        line-height: 16px;
        float: right;
    }

    &_size {
        font-size: 0.625rem;
        font-weight: 300;
        letter-spacing: 0.56px;
        line-height: 16px;
    }

    &_number {
        float: right;
    }

    &_interaction {
        margin: 8px 0;
    }
}
