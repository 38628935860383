@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";

.header {
  font-family: "steelfish", sans;
  text-transform: uppercase;
  color: #f67599;
  font-size: 2.25rem;
  letter-spacing: 0.04rem;
  line-height: 2.25rem;
  margin: 20px 0 10px;
}

.banner {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 300px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  perspective: 1000;
}
@media (min-width: $screen-md-min) {
  .banner {
    height: 400px;
  }
}
@media (min-width: $screen-lg-min) {
  .banner {
    height: 867px;
  }
}

.title {
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  text-align: center;
}

.subtitle {
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  text-align: center;
}
