@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";

.breadcrumb {
  font-family: "museosans", sans;
  font-weight: 500;
  color: #888b8d;
  font-size: 0.63rem;
  line-height: 0.75rem;
  letter-spacing: 0;
  text-transform: uppercase;
  position: relative;
  top: -1.5rem;
  height: 0;
  overflow: visible;

  & a:link,
  & a:visited,
  & a:active {
    color: #888b8d;
    vertical-align: middle;
  }
}

.sizeGuide {
  text-decoration: underline;
  text-transform: none;
  margin-left: 10px;
  font-size: 18px;
}

.sizeGuide:hover {
  cursor: pointer;
}

.breadcrumbArrow {
  margin-right: 0.5em;
}

.header {
  @include bless-h3;
  margin-bottom: 40px;
}

.productTitle {
  @include bless-h2;
}

.productPrice {
  font-size: 2rem;
}

.productSizeTag {
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
}

.productDescription {
  line-height: 1.75rem;
}

.productClaimedMessage {
  color: $logo-color-pink;
}

.add_to_bag {
  @include bless-button($btn-primary);
}

.remove_from_cart {
  @include bless-button($btn-remove);
}

.disabled {
  @include bless-button($btn-disabled);
}

.productImg {
  width: 100%;
  height: auto;
  max-height: 738px;
  object-fit: cover;
  border: 1px solid rgb(192, 192, 192);
}

.thumbnailImagesWrapper {
  padding: 15px 0 0 15px;
}

.thumbnailImageWrapper {
  padding-bottom: calc(25% - 15px);
  width: calc(25% - 15px);
  overflow: hidden;
  border: 1px solid rgb(192, 192, 192);
  position: relative;
  margin: 0 15px 15px 0;
}

.thumbnailImg {
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.notFound {
  @include bless-h2;
  margin: 30px 0;
}

.size_select {
  color: #000000;
  border-color: #000000;
  border-radius: 0px;
  background-color: #ffffff;
  margin-right: 10px;
  width: 50px;
  padding: 0.6em;
}
.size_select:hover {
  background-color: #f67599;
  color: #ffffff;
  border-color: #f67599;
}

.selected {
  background-color: #f67599;
  color: #ffffff;
  border-color: #f67599;
}
