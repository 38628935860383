@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";

.checkoutContainer {
  display:flex;
  flex-wrap: wrap;
}

.checkoutSection {
  flex: 1;
  min-width:21%;
  max-width:25%;
  margin: 0 0 0 0;
  padding-bottom:30px;
  
  padding-left:25px;
  padding-right:25px;
  border-right: 1px solid #EAEAEA;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  & p {
    font-size: 12px;
    color: #4A4A4A;
    letter-spacing: 0.67px;
    line-height: 23px;
  }
}


@media (max-width: $screen-lg-max) and (min-width: $screen-lg-min) {
  .checkoutSection {
    min-width:34%;
    max-width:50%;
    padding-top: 25px;

    &:nth-child(-n+2) {
      padding-top: 0;
      border-bottom: 1px solid #EAEAEA;
    }

    &:nth-child(2n+1) {
      padding-left: 0;
      border-right: 1px solid #EAEAEA;
    }

    &:nth-child(2n+2) {
      padding-right: 0;
      border-right: none;
    }
  }
}

@media (max-width: $screen-md-max) {
  .checkoutSection {
    min-width:51%;
    max-width:100%;
    border-bottom: 1px solid #EAEAEA;
    border-right: none;
    margin-bottom: 35px;
    padding-left: 0;
    padding-right: 0;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}


.border_left {
  margin-bottom: 30px;

  & + & {
    border-left: 1px solid #EAEAEA;
  }
}

.shopping_cart_size {
  font-family: "museosans";
  font-weight: 300;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.67px;
  line-height: 16px;
}

.checkout_item {
  & + & {
    margin-top: 8px;
  }

  &_image_area {
    width: 70px;
    height: 80px;
    overflow: hidden;
    position: relative;
    float: left;
    margin-right: 10px;
  }
  
  &_image {
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}
  
.modal {
  // display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-y: initial;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.hidden {
  display: none;
}

.modal_content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  height: 50%;
  overflow-y: auto;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

}

.close:hover .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.policyLink {
  color: #4792e6;
  cursor: pointer;
}

.policyText {
  font-family: "museosans";
  font-size: 10px;
  margin-top: 15px;
  color: #4a4a4a;
  letter-spacing: 0.2px;
  line-height: 17px;
}

.cvvDisplay {
  display: none;
}

.cvv1 {
  margin-top: 20px;
}

.cvv2 {
  margin-top: 20px;
  margin-left: 20px;
}

.cardImages {
  clear: both;
}

.other_albums {
  margin-left: 40px;
}

.checkoutItem {
  float: left;
  clear: both;
  margin-bottom: 20px;
}

.checkoutItemDetails {
  padding-top: 10px;
}

.billing_column {
  border-right: 1px solid #eaeaea;
  opacity: 100;
  max-width: 25%;
  padding-left: 35px;
  padding-right: 25px;
}

.review_column {
  border-right: 1px solid #eaeaea;
  opacity: 100;
  max-width: 20%;
  display: inline-block;
}

.column_number {
  background: #4792e6;
  border-radius: 2px;
  padding-left: 8px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 24px;
  width: 24px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  margin-right: 8px;
}

.info_display {
  text-align: left;
  font-family: "museosans";
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.67px;
  line-height: 23px;
  clear: both;
}

.input_header {
  @include bless-input-labels;
}

.input_header_year {
  margin-left: 15px;
  font-family: "museosans";
  font-size: 13px;
  color: #4a4a4a;
  float: left;
}

.input_header_first_name {
  font-family: "museosans";
  font-size: 13px;
  color: #4a4a4a;
  float: left;
  clear: both;
}

.input_header_shipping {
  font-family: "museosans";
  font-size: 13px;
  color: #4a4a4a;
  float: left;
  margin-right: 140px;
}

.input_header_state {
  font-family: "museosans";
  font-size: 13px;
  color: #4a4a4a;
  float: left;
  margin-right: 38px;
  margin-left: 18px;
}

.input_header_card {
  font-family: "museosans";
  font-size: 13px;
  color: #4a4a4a;
  float: left;
  margin-right: 55px;
}

.summary {
  background: #f5f4f4;
  padding: 20px;
  margin: 20px 0;
}

.summaryComplete {
  background: #f5f4f4;
  height: 200px;
  width: 260px;
  clear: both;
}

.total {
  font-weight: bold;
}

.totals {
  float: left;
  text-align: left;
  font-family: "museosans";
  font-size: 14px;
  color: #4a4a4a;
  width: 60%;
  padding-left: 10px;
  padding-top: 10px;
}

.totalValues {
  float: right;
  font-family: "museosans";
  font-size: 14px;
  color: #4a4a4a;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 10px;
}

.side_border {
  border-right: 1px solid #eaeaea;
  opacity: 100;
  display: inline-block;
}

.order_complete_column {
  border-right: 1px solid #eaeaea;
  opacity: 100;
  clear: both;
}

.input_box {
  width: 250px;
  height: 39px;
  border-radius: 0px;
  font-family: "museosans";
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.67px;
  line-height: 16px;
  border: 1px solid #888b8d;
  margin-bottom: 15px;
  float: left;
}

.expiration_input {
  width: 120px;
  height: 39px;
  border-radius: 0px;
  font-family: "museosans";
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.67px;
  line-height: 16px;
  border: 1px solid #888b8d;
  margin-bottom: 15px;
  float: left;
}

.expiration_year {
  margin-left: 10px;
  width: 120px;
  height: 39px;
  border-radius: 0px;
  font-family: "museosans";
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.67px;
  line-height: 16px;
  border: 1px solid #888b8d;
  margin-bottom: 15px;
  float: left;
}

.manage_header {
  font-family: "steelfish";
  font-size: 36px;
  color: #f67599;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: left;
}

.edit {
  font-family: "museosans";
  font-size: 12px;
  color: #007aff;
  line-height: 17px;
  cursor: pointer;
}

.cvv {
  margin-left: 105px;
  font-family: "museosans";
  font-size: 11px;
  float: right;
  cursor: pointer;
  color: #007aff;
  line-height: 17px;
}

.checkoutHeader {
  font-family: "museosans";
  font-size: 18px;
  color: #4a4a4a;
  // float: left;
  margin-bottom: 40px;
  margin-right: 40px;
}

.proceedToPay {
  @include bless-button($btn-primary);
}

.remove_from_cart {
  @include bless-button($btn-remove);
}

.disabled {
  @include bless-button($btn-disabled);
}

.proceedToPayDisabled {
  @extend .proceedToPay;
  background: #dd7fd3;
  cursor: not-allowed;
}

.proceedToPayLoading {
  @extend .proceedToPay;
  background: #dd7fd3;
  cursor: wait;
}

.input_state {
  width: 60px;
  height: 39px;
  border-radius: 0px;
  font-family: "museosans";
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.67px;
  line-height: 16px;
  border: 1px solid #888b8d;
  margin-bottom: 5px;
  float: left;
}

.input_zip {
  width: 180px;
  height: 39px;
  border-radius: 0px;
  font-family: "museosans";
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.67px;
  line-height: 16px;
  border: 1px solid #888b8d;
  margin-bottom: 5px;
  margin-left: 10px;
  float: left;
}

.same_as_shipping {
  font-family: "museosans";
  font-size: 10px;
  color: #858585;
  line-height: 17px;
}

.img_style {
  width: 58px;
}

.shopping_cart_size {
  font-family: "museosans";
  font-weight: 300;
  font-size: 12px;
  color: #4A4A4A;
  letter-spacing: 0.67px;
  line-height: 16px;
}

.center {
  text-align: center;
}