@import "../../../scss/variables";
@import "../../../scss/mixins";

.itemImageArea {
  overflow: hidden;
  border: 1px solid rgb(192, 192, 192);
  position: relative;
  padding-bottom: 100%;

  .itemImage {
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.itemCardText {
  padding-top: 9px;
  font-family: "museosans", sans;
  font-weight: 300;
  color: #4a4a4a;
  letter-spacing: 0.78px;
  font-size: 14px;
  line-height: 16px;

  .description {
    float: left;
    width: fit-content;
    max-width: 100%
  }

  .title {
    font-weight: 700;
    text-transform: uppercase;
    width: fit-content;
    padding-top: 1rem;
  }

  .subtitle {
    font-size: 12px;
    letter-spacing: 0.67px;
    line-height: 16px;
  }

  .price {
    float: right;
    text-align: right;
    padding-left: 1rem;
    width: fit-content;
  }

  .originalPrice {
    color: #f67599;

    & > span {
      text-decoration: line-through;
    }
  }

  .priceOverride {
    font-weight: 700;
  }
}

.add_to_bag {
  @include bless-button($btn-primary);
  width: 100%;
  padding: 0.6em;
}

.add_to_bag_popover {
  max-width: 300px;
}

.disabled {
  @include bless-button($btn-disabled);
  width: 100%;
  padding: 0.6em;
}

.size_select {
  color: #000000;
  border-color: #000000;
  border-radius: 0px;
  background-color: #ffffff;
  margin-right: 10px;
  width: 50px;
  padding: 0.6em;
}
.size_select:hover {
  background-color: #f67599;
  color: #ffffff;
  border-color: #f67599;
}

.remove_from_cart {
  @include bless-button($btn-remove);
  width: 100%;
  padding: 0.6em;
}

.sizeGuide {
  text-decoration: underline;
  text-transform: none;
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
}

.overlayOpen {
  background-color: black;
  opacity: .5;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 501;
  width: 100%;
  height: 100%;
}
