@import "../../scss/variables";
@import "../../scss/mixins";

.clickContainer {
  opacity: 0;
  background: $logo-color-grey;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: opacity 0.3s ease-out;
  z-index: -1;
}

.menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 240px;
  background: #fff;
  overflow-y: auto;
  transform: translate(-100%);
  transition: transform 0.3s ease-out;
  box-shadow: none;
  z-index: 999;
}

.show {
  & .menu {
    transform: translate(0);
    box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
  }

  & .clickContainer {
    opacity: 0.8;
    z-index: 990;
    width: 100%;
  }
}

.menuLink {
  text-transform: uppercase;
  font-weight: 500;
  color: $logo-color-grey;
  font-size: 0.88rem;
  letter-spacing: 0.05rem;
  line-height: 1rem;
  display: block;
  padding: 10px;

  &:hover {
    text-decoration: none;
    color: $logo-color-blue;
    background: lighten($logo-color-grey, 41%);
  }

  &:global(.active) {
    border-bottom: none;

    & .fullMenuLinkText {
      border-bottom: 2px solid $logo-color-fuschia;
    }
  }
}

.mobileMenuIcon {
  position: absolute;
  top: 20px;
  left: 0;
}

:global(.stickyNav) {
  .mobileMenuIcon {
    top: 6px;
    left: 6px;
  }
}

.closeMenu,
.mobileMenuIcon {
  color: $logo-color-grey;
}

.shortMenuLinkText {
  display: none;
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .shortMenuLinkText {
    display: initial;
  }

  .fullMenuLinkText {
    display: none;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: $screen-sm-min) {
  .menu {
    position: static;
    height: auto;
    width: auto;
    background: none;
    overflow-y: visible;
    transform: translate(0);
    transition: transform 0s;
    z-index: auto;
  }

  .menuLink {
    display: inline-block;
    padding: 0;

    &:hover {
      background: none;
    }

    &:global(.active) {
      border-bottom: 2px solid $logo-color-fuschia;
    }
  }

  .menuLink {
    & + & {
      margin-left: 20px;
    }
  }
}
