@import "../../scss/variables";

.container {
  margin-bottom: 24px;
}

.clearFix {
  clear: both;
  height: 0;
}

.header {
  padding-top: 24px;
  position: relative;
}

$xsm-logo-size: 24px;

.logo {
  display: inline-block;
  line-height: $xsm-logo-size;
  vertical-align: top;
  padding-right: 4px;
  float: left;

  & img {
    width: $xsm-logo-size;
    height: $xsm-logo-size;
    vertical-align: text-bottom;
  }
}

.welcomeTitle {
  font-family: "museosans", sans;
  font-weight: 300;
  color: $logo-color-grey;
  line-height: 1.13rem;
  letter-spacing: 0.02rem;
  padding-top: 0.31rem;
  display: none;
}

.storeLogoAndName {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 48px;
    line-height: 48px;
    font-size: 48px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 12px;
    margin-bottom: 12px;

    .storeLogo, .storeName {
        margin-left: 20px;
        margin-right: 20px;
    }

    .storeLogo {
        flex-shrink: 1;
        height: inherit;

        img {
            height: 100%;
        }
    }

    .storeName {
        flex-grow: 1;
        font-family: "steelfish", sans;
        font-size: inherit;
        text-transform: uppercase;
        letter-spacing: 0.06rem;
        line-height: inherit;

        > a {
            display: inline-block;
            text-align: left;
            line-height: 1.2em;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            color: $logo-color-pink;
            text-decoration: none;
        }
    }
}


hr.rainbow {
  background-image: linear-gradient(
    90deg,
    $logo-color-yellow 0%,
    $logo-color-orange 19%,
    $logo-color-pink 34%,
    $logo-color-fuschia 50%,
    $logo-color-purple 66%,
    $logo-color-blue 82%,
    $logo-color-mint 100%
  );
  height: 0.19rem;
  margin: 0;
  border: none;
  display: none;
}

.nav {
  float: left;
  margin-top: 0.5rem;
}

.headerCartLinks {
  user-select: none;
  cursor: pointer;
  font-family: "museosans", sans;
  font-weight: 500;
  text-transform: uppercase;
  color: $logo-color-grey;
  letter-spacing: 0.78px;
  position: absolute;
  top: 21px;
  right: 0;
  width: 46px;
  height: 34px;
  // overflow: hidden;
}

.myBag {
  display: none;
}

.cartCountArea {
  position: absolute;
  right: 2px;
  top: 6px;
}

.cartCount {
  width: 22px;
  display: inline-block;
  text-align: center;
  line-height: 24px;
}

.bagIcon {
  display: inline-block;
  line-height: 30px;
  width: 15px;
  text-align: center;
  padding-right: 0px;
  line-height: 24px;
}

.shoppingCartPlacement {
  position: absolute;
  right:0;
  top:40px;
}

.flexBox {
  text-align: center;
  margin: auto;
  width: 80px;
  text-align: right;
  margin-right: 5px;
}

.mobileTimerContainer {
  float: right;
}

.headerCartLinks:global(.has-items) {
  background-color: $logo-color-pink;
  // transition: width 0.5s ease, border-radius 0.25s ease;
  transition: width 0.5s ease;
  width:116px;
  border-radius: 3px;

  .timer, .bagIcon, .cartCount {
    color: #ffffff;
  }
}

.timer {
  width: 45px;
  text-align: right;
  position: absolute;
  top:8px;
  right:60px;
  
  font-family: "museosans";
  font-size: 12px;
}

// @media (max-width: $screen-md-min) {
//   .timer {
//     min-width: 90px;
//     padding-top: 0px;
//     padding-bottom: 0px;
//   }  
// }

@media (max-width: $screen-xs-max) {
    .storeLogoAndName {
        font-size: 24px;
        line-height: 24px;
        height: 24px;
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 1px;
        margin-bottom: 0;
        padding-left: 48px;
        padding-right: 48px;

        .storeLogo, .storeName {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}

:global(.stickyNav) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 500;
  background: #fff;
  height: 50px;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.1);

  & .header {
    width: 100%;
    padding: 0 15px;
    float: none;
  }

  & .headerCartLinks {
    top: 0;
    margin: 0.45rem 15px;
  }

  & .nav {
    margin: 0.75rem 15px 0.75rem 0;
  }

  & .welcomeTitle,
  & .storeName {
    display: none;
  }

  & .mobileMenuIcon {
    top: 6px;
    left: 6px;
  }

  & .mobileTimerContainer {
    position: absolute;
    right: 54px;
    top: 4px;
  }

  & .mobileTimer {
    display: inline-block;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {
  $sm-logo-size: 6.5rem;

  .container {
    margin-bottom: 42px;
  }

  .logo {
    position: absolute;
    bottom: 0;
    right: 100%;
    width: $sm-logo-size;
    height: $sm-logo-size;
    float: left;
    display: block;
    padding-right: 0;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .header {
    width: 100%;
    float: right;
    padding-top: 42px;
  }

  :global(.stickyNav) {
    height: auto;

    & .mobileTimerContainer {
      right: 130px;
      top: 6px;
    }

    & .headerCartLinks {
      margin-right: 0;
    }
  }

  .storeNameText {
    display:inline;
    line-height: 1em;
  }

  hr.rainbow,
  .welcomeTitle {
    display: block;
  }

  .headerCartLinks {
    float: right;
    margin-top: 0.25rem;
    position: relative;
    top: 0;
  }

  .allAlbums {
    position: relative;
    line-height: initial;
  }

  .allAlbumsText {
    display: inline;
  }

  .linkUnderline {
    border-bottom: 2px solid $logo-color-pink;
    padding-bottom: 2px;
  }

  .allAlbumsIcon {
    display: none;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  $md-logo-size: 7.75rem;

  .logo {
    width: $md-logo-size;
    height: $md-logo-size;
  }

  .header {
    width: 100%;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
}

// TODO: Try to remove global. Break Timer and Cart into reusable components for live full screen mode.
:global(.full-screen-live) {
  & .headerCartLinks {
    position: fixed;
    top: 10px;
    right: 42px;
    z-index: 400;
    
    .timer, .bagIcon, .cartCount {
      color: #ffffff;
    }
  }

  // .bagCount {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   font-size: 0.5rem;
  //   text-align: center;
  //   background: $logo-color-pink;
  //   color: #fff;
  //   width: 17px;
  //   line-height: 17px;
  //   border-radius: 50%;
  // }

  // .bagIcon {
  //   display: inline-block;
  //   line-height: 30px;
  //   width: 30px;
  //   text-align: center;
  // }

  & .mobileTimer {
    position: fixed;
    top: 7px;
    right: 74px;
    z-index: 400;
  }
}
