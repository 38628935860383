@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";

$light-text: #abb0be;

.container {
  position: relative;
  cursor: auto;
  text-transform: none;
  user-select: none;
}

.shopping_cart_size,
.shopping_cart_hanger_code {
  font-family: "museosans";
  font-weight: 300;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0.67px;
  line-height: 16px;
}

.summaryComplete {
  background: #f5f4f4;
  padding: 20px;
  margin-bottom: 20px;
}

.totals {
  font-family: "museosans";
  font-size: 14px;
  color: #4a4a4a;

  & + .totals {
    margin-top: 20px;
  }
}

.totalsCurrency {
  float: right;
}

.finalTotal {
  font-weight: bold;
}

nav {
  padding: 20px 0 40px 0;
  background: #f8f8f8;
  font-size: 16px;

  .navbar-left {
    float: left;
  }

  .navbar-right {
    float: right;
  }
  ul {
    li {
      display: inline;
      padding-left: 20px;
      a {
        color: #777777;
        text-decoration: none;

        &:hover {
          color: black;
        }
      }
    }
  }
}

.shopping_cart {
  border: 1px solid #d6d6d6;
  background: #ffffff;
  width: 340px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 2px;
  padding: 20px;
  z-index: 200;
  box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.12),
    0 12px 15px 0 rgba(0, 0, 0, 0.11);

  &_header {
    margin-bottom: 20px;
    color: #4a4a4a;
    font-size: 18px;
  }

  &_total {
    float: right;
  }

  &_list {
    list-style-type: none;
    padding-left: inherit;
    overflow: auto;
    padding-left: 0px;
    max-height: 256px;
    margin-bottom: 20px;
  }

  &_item {
    &_image {
      float: left;
      margin-right: 10px;
      width: 70px;
      height: 80px;
    }

    & + & {
      margin-top: 8px;
    }

    &_remove {
      color: #007aff;
      font-size: 11px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// TODO: Try to remove global. Break Timer and Cart into reusable components for live full screen mode.
:global(.full-screen-live) {
  .shopping_cart {
    right: -34px;
  }
}

// TODO: Better fix for cart on mobile
@media (max-width: 370px) {
  .shopping_cart {
    width: 303px;
    right: -15px;
  }
}

.emptyCart {
  font-family: "museosans";
  font-weight: 500;
  text-align: center;
  padding: 1em;
}

.header {
  font-family: "museosans";
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  padding: 1em;
}

.cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}

.button {
  @include bless-button($btn-primary);
}

.button_disabled {
  @include bless-button($btn-disabled);
}

.shopping_cart_item a {
  color: $logo-color-grey;
  text-decoration: none;
  font-family: "museosans";
  font-size: 14px;
  font-weight: 500;
}

.shopping_cart_item a:hover {
  text-decoration: none;
}

.shopping_cart_item_image_area {
  width: 70px;
  height: 80px;
  overflow: hidden;
  position: relative;
  float: left;
  margin-right: 10px;
}

.shopping_cart_item_image {
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
