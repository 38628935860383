@import "../../scss/variables";
@import "../../scss/mixins";

.bless_h3 {
  @include bless-h3;
}

.live_album {
  position: relative;
  border: solid 2px #d6d6d6;

  &_active {
    border: solid 2px #fd3d3e;
    background: #feedfc;
  }

  &_info {
    padding: 15px;
    font-size: 0.875rem;
  }

  &_details {
    padding-bottom: 15px;
  }

  &_datetime {
    font-weight: 300;
    letter-spacing: 0.02rem;
  }

  &_time_component {
    white-space: nowrap;
  }

  &_header {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &_button {
    @include bless-button($btn-disabled);
    font-size: 0.875rem;

    &_active {
      @include bless-button($btn-primary);
      font-size: 0.875rem;
    }
  }
}

.live_column_header {
  font-size: 0.875rem;
}

.upcoming_live {
  position: relative;
  border: 1px solid #d6d6d6;
  font-size: 0.625rem;
  letter-spacing: 0.2px;
  line-height: 15px;

  &_img {
    width: 44%;
    float: left;
  }

  &_info {
    width: 56%;
    right: 0px;
    padding: 15px;
    position: absolute;
    height: 100%;
  }

  &_header {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
    }
  }

  &_button_disabled {
    @include bless-button($btn-disabled);
    font-size: 0.625rem;
    line-height: 1;
    padding: 14px 0;
    position: absolute;
    bottom: 15px;
    width: calc(100% - 30px);
    font-weight: 500;
    letter-spacing: 0.03rem;
  }

  &_button {
    @include bless-button($btn-primary);
    font-size: 0.625rem;
    line-height: 1;
    padding: 14px 0;
    position: absolute;
    bottom: 15px;
    width: calc(100% - 30px);
    font-weight: 500;
    letter-spacing: 0.03rem;
  }
}

.badge {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 0.875rem;
  line-height: 25px;

  &_live {
    background: #fd3d3e;
  }

  & + & {
    margin-left: 5px;
  }

  &_wrapper {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
  }
}
