@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/functions";


.albumHeader {
  h2 {
      font-family: 'steelfish', sans;
      text-transform: uppercase;
      color: $logo-color-pink;
      font-size: 2.25rem;
      letter-spacing: 0.04rem;
      line-height: 2.69rem;
      margin-bottom: 2.5rem;
  }
}

.input_box {
  width: 250px;
  height: 39px;
  border-radius: 0px;
  font-family: museosans;
  font-size: 12px;
  color: $font-color-default;
  letter-spacing: 0.67px;
  line-height: 16px;
  border: 1px solid $logo-color-grey;
  margin-bottom: 15px;
}

.searchButton {
  margin-left: 20px;
  font-family: museosans;
  text-transform: uppercase;
  margin-right: 2px;
  background: $logo-color-fuschia;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  text-decoration: none;
  border: 0px;
}

.filterButton {
  margin-right: 10px;
}

.searchBox {
  margin-bottom: 40px;
  margin-right: 10px;
  margin-left: 20px;
}

.albumTimer {
  background-color: $logo-color-pink;
  color: #ffffff;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timerTitle {
  font-family: "Steelfish";
  text-transform: uppercase;
  padding-left: 20px;
  font-style: normal;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: 0.6px;
  float: left;
  min-width: auto;
  max-width:50%;
}

.timerDetailText {
  font-family: "Covered By Your Grace";
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  float: left;
  width: 33.33%;
  flex: 1;
}

.timerClock {
  font-family: "Covered By Your Grace";
  padding-top: 10px;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 16px;
  float: right;
  text-align: right;
  width: auto;
  padding-right: 20px;
}

.counterColumn {
  display: inline-block;
  width: 35px;
  text-align: center;
}

.timerClockText {
  font-family: "museosans";
  display: inline-block;
  font-weight: 300;
  font-size: 10px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 16px;
  width: 35px;
  text-align: center;
  text-transform: uppercase;
}