@import "../../scss/variables";
@import "../../scss/mixins";

.LiveEventsBanner {
  background-color: $logo-color-purple;
  border-radius: 10px;
  height: 240px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  margin-top: 20px;
}

.activeEvent {
  background-color: $logo-color-fuschia;
}

@media (max-width: 430px) {
  .activeEvent {
    background-color: $logo-color-fuschia;
    height: 190px;
  }
}

@media (min-width: 431px) {
  .activeEvent {
    background-color: $logo-color-fuschia;
    height: 215px;
  }
}

@media (min-width: $screen-sm-min) {
  .activeEvent {
    background-color: $logo-color-fuschia;
    height: 240px;
  }
}

.upcomingEvent {
  background-color: $logo-color-purple;
}

.Image {
  border-radius: 10px;
}

.badge {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 0.875rem;
  line-height: 25px;

  &_live {
    background: #fd3d3e;
  }

  & + & {
    margin-left: 5px;
  }

  &_wrapper {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 25px;
  }
}

.dateContainer {
  padding-top: 1rem;
}

.text {
  &_header {
    color: white;
    font-family: "museosans", sans;
    font-size: 0.9rem;
    font-weight: lighter;
    text-transform: uppercase;
  }

  &_title {
    color: white;
    font-family: "steelfish", sans;
    line-height: 1.2em;
    letter-spacing: 0.06rem;
    font-size: 1.5rem;
  }

  &_date {
    color: white;
  }

  &_upcomingEvents {
    padding-top: 1rem;
    color: white;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}

.addToCalendar {
  &_container {
    padding-top: 1rem;
  }

  &_btn {
    @include bless-button(white, $logo-color-purple);
    max-width: 95%;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-transform: none;

    > a {
      color: $logo-color-fuschia;

      &:hover {
        color: $logo-color-fuschia;
        text-decoration: none;
      }
    }

    @media (min-width: $screen-sm-min) {
      font-size: 1rem;
    }
  }
}

.joinEventBtn {
  @include bless-button(white, $logo-color-fuschia);
  max-width: 95%;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-transform: none;
  font-size: 1rem;

  > a {
    color: $logo-color-fuschia;

    &:hover {
      color: $logo-color-fuschia;
      text-decoration: none;
    }
  }
}
